<script setup>
import { onBeforeUnmount, onMounted } from 'vue';

import ApplicationLogo from '@/Components/ApplicationLogo.vue';

defineProps({
    title: String,
});

onMounted(() => {
    document.documentElement.classList.add('h-full', 'bg-white');
    document.body.classList.add('h-full');
    document.getElementById('app').classList.add('h-full');
});

onBeforeUnmount(() => {
    document.documentElement.classList.remove('h-full', 'bg-white');
    document.body.classList.remove('h-full');
    document.getElementById('app').classList.remove('h-full');
});
</script>

<template>
    <div class="flex min-h-full">
        <div
            class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
        >
            <div class="mx-auto w-full max-w-sm lg:w-96">
                <div class="mb-6">
                    <ApplicationLogo class="w-52 text-emerald-500" />
                    <h2 class="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                        {{ title }}
                    </h2>
                </div>
                <slot />
            </div>
        </div>
        <div class="relative hidden w-0 flex-1 lg:block">
            <img
                class="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
            />
        </div>
    </div>
</template>
